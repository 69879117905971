import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  Link,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { CircleCancel, InfoModal, theme } from '@konecorp/ui-library';

import Context from '../../context';
import { API_TYPE, fetchEmployeeFullName, get, put, remove } from '../../helpers/fetch';
import {
  ActivityDifferentiator,
  Assignment,
  Contact,
  Handover,
  InstallationStatus,
  SubcontractorRecord,
} from '../../schemas';
import { truncateNetworkFromIndexedDB } from '../../helpers/indexedDB';
import { useGetToken } from '../../hooks/useGetToken';
import { useCheckConnection } from '../../hooks/useCheckConnection';
import { useGetCurrentUserRole } from '../../hooks/useGetCurrentUserRole';
import { useIfSubcontractor } from '../../hooks/useIfSubcontractor';
import { useGetUserData } from '../../hooks/useGetUserData';
import InstallationStartingTester from '../../components/InstallationStartingTester';
import { formatDate } from '../../helpers/formating';
import { useHistory } from 'react-router-dom';
import AddButton from '../../components/AddButton';
import { DialogType } from '../../components/TeamList';
import ContactForm from '../../components/ContactForm';
import ContactInfoDetails from '../../components/ContactInfo';
import { grey } from '@material-ui/core/colors';
import ErrorMessageDialog from '../../components/ErrorMessageDialog';
import { DatePicker } from '../../components/DatePicker';

enum InfoModalType {
  NONE,
  IS_OFFLINE,
  CONFIRMATION,
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      margin: theme.spacing(5),
      maxWidth: '100vw',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      '& button': {
        marginTop: theme.spacing(5),
      },
    },
    greyHeadline: {
      backgroundColor: grey[200],
      padding: theme.spacing(1),
      textTransform: 'uppercase',
    },
    upperCase: {
      textTransform: 'uppercase',
    },
    divider: {
      width: '100%',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    additionalInfoLinks: {
      marginTop: theme.spacing(3),
      textDecoration: 'underline',
    },
    closeDialogIcon: {
      width: 30,
      height: 30,
      position: 'absolute',
      zIndex: 1,
      right: theme.spacing(1.5),
      top: theme.spacing(1.5),
    },
  })
);
type InstallationStartingProps = {
  contacts: Contact[];
  setContacts: (contacts: Contact[]) => void;
  itemToRemove: Contact | null;
  setItemToRemove: (item: Contact) => void;
};
const InstallationStarting = (props: InstallationStartingProps): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isOnline] = useCheckConnection();
  const [role] = useGetCurrentUserRole();
  const { updateIsLoading, networkNumber, installationData, updateErrorMessage } =
    useContext(Context);
  const [showDialog, setShowDialog] = useState<DialogType>(DialogType.NONE);
  const [selectedItem, setSelectedItem] = useState<Contact | null>(null);
  const [dialogType, setDialogType] = useState<InfoModalType>(InfoModalType.NONE);
  const [allowStart, setAllowStart] = useState<boolean>(false);
  const [supervisorFullName, setSupervisorFullName] = useState<string>('');
  const [handover, setHandover] = useState<Handover | null>(null);
  const [isSubcontractor] = useIfSubcontractor();
  const [employeeId] = useGetUserData();
  const [errorMsg, setErrorMsg] = useState(false);
  const history = useHistory();
  const { itemToRemove, setItemToRemove } = props;
  const [newDate, setNewDate] = useState(true);
  const [inspectDate, setInspectDate] = useState(
    new Date(installationData?.inspectionDate ?? Date.now())
  );
  const [getTokenFunction] = useGetToken();

  const nextStatus = (() => {
    switch (role) {
      case ActivityDifferentiator.INST:
        return InstallationStatus.INSTALLER_ACCEPTED;
      case ActivityDifferentiator.CMSN:
        return InstallationStatus.TESTER_ACCEPTED;
      default:
        return undefined;
    }
  })();

  const isInstaller = role === ActivityDifferentiator.INST;
  const isTester = role === ActivityDifferentiator.CMSN;

  useEffect(() => {
    if (installationData?.inspectionDate && installationData?.inspectionDate !== null) {
      setInspectDate(new Date(installationData.inspectionDate));
      setNewDate(false);
    }
    const fetchSupervisorFullName = async (accessToken: string): Promise<string> => {
      return installationData?.supervisorNumber
        ? await fetchEmployeeFullName(installationData.supervisorNumber, accessToken)
        : '';
    };

    const fetchHandoverData = async (accessToken: string): Promise<Handover | null> => {
      try {
        return await get(
          `v1/installations/${networkNumber}/handovers/latest`,
          accessToken
        );
      } catch (error) {
        console.error('error while fetching handover data', error);
      }
      return null;
    };

    (async () => {
      updateIsLoading(true);
      const accessToken = await getTokenFunction();

      if (isInstaller || isTester) {
        const supervisorFullName = await fetchSupervisorFullName(accessToken);
        setSupervisorFullName(supervisorFullName);
      }

      if (isTester) {
        const handover = await fetchHandoverData(accessToken);
        setHandover(handover);
      }

      updateIsLoading(false);
    })();
  }, [installationData]);
  const sendContact = async (
    contactToSend: Contact,
    setContacts: (contacts: Contact[]) => void,
    contacts: Contact[]
  ) => {
    const token = await getTokenFunction();

    try {
      updateIsLoading(true);
      const returnedContact: Contact = await put(
        `v1/installations/${networkNumber}/contacts`,
        token,
        API_TYPE.APPLICATION,
        contactToSend
      );

      setShowDialog(DialogType.NONE);
      setSelectedItem(null);

      const updatedContacts =
        contactToSend.guid !== returnedContact.guid
          ? [...contacts, returnedContact]
          : contacts.map((contact) =>
              contact.guid === returnedContact.guid ? returnedContact : contact
            );
      setContacts(updatedContacts);
    } catch (error) {
      updateErrorMessage({ message: t('teamList.cannotSaveContact'), error });
    } finally {
      updateIsLoading(false);
    }
  };
  const openInstallationExecution = () => {
    // hard reload the app so when the app go to /execution it has a clean context
    // and trigger the caching again
    if (isSubcontractor) {
      window.location.replace(`/subcontractor/${networkNumber}/execution`);
    } else {
      window.location.replace(`/${networkNumber}/execution`);
    }
  };

  const onStartingInstallation = async () => {
    try {
      if (!isOnline) {
        setDialogType(InfoModalType.IS_OFFLINE);
        return;
      }

      updateIsLoading(true);
      const accessToken = await getTokenFunction();

      await put(
        `v1/installations/${networkNumber}/status`,
        accessToken,
        API_TYPE.APPLICATION,
        { status: nextStatus }
      );

      await truncateNetworkFromIndexedDB(networkNumber);

      switch (role) {
        case ActivityDifferentiator.INST:
          setDialogType(InfoModalType.CONFIRMATION);
          break;
        case ActivityDifferentiator.CMSN:
          openInstallationExecution();
          break;
      }
    } catch (e) {
      console.error('Error when trying to start an installation, error: ', e);
    } finally {
      updateIsLoading(false);
    }
  };
  const putInsDateSoldHrs = async (inspectionDate: string): Promise<void> => {
    const token = await getTokenFunction();
    return await put(
      `v1/installations/${networkNumber}/insDateSoldHours`,
      token,
      API_TYPE.APPLICATION,
      { type: 'inspectionDate', value: inspectionDate }
    );
  };
  const handleDateChange = async (ISOFormattedDate: string) => {
    if (installationData) {
      installationData.inspectionDate = ISOFormattedDate;
    }
    if (ISOFormattedDate) {
      setInspectDate(new Date(ISOFormattedDate));
      await putInsDateSoldHrs(ISOFormattedDate);
    } else {
      setNewDate(true);
    }
  };
  const handleChecklistButtonClick = (): void => {
    const rootPath = isSubcontractor ? '/subcontractor' : '';
    try {
      history.push(`${rootPath}/${networkNumber}/starting/pre-install-checklist`);
    } catch (error) {
      console.error('error while navigating', error);
    }
  };

  const handleInstallPlanButtonClick = () => {
    const rootPath = isSubcontractor ? '/subcontractor' : '';
    try {
      history.push(`${rootPath}/${networkNumber}/starting/installation-plan`);
    } catch (error) {
      console.error('error while navigating', error);
    }
  };

  const getDialogMessage = (): string => {
    if (dialogType === InfoModalType.IS_OFFLINE) {
      return t('installationStarting.onlyOnline');
    } else {
      return t('installationStarting.networkStartedMessageWithSupervisorFullName', {
        supervisorFullName: supervisorFullName,
      });
    }
  };

  const onDialogClose = (): void => {
    if (dialogType === InfoModalType.IS_OFFLINE) {
      setDialogType(InfoModalType.NONE);
    } else {
      openInstallationExecution();
    }
  };

  if (!installationData || !role) return <></>;

  const { assignees, subcontractors } = installationData;

  const ContactInfo = () => {
    const { customer } = installationData;

    return (
      <Grid container item spacing={2}>
        <Grid item xs={6}>
          <Typography className={classes.upperCase}>
            {t('installationStarting.network')}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>{networkNumber}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography className={classes.upperCase}>
            {t('installationStarting.supervisor')}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>{supervisorFullName}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography className={classes.upperCase}>
            {t('installationStarting.location')}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>{customer?.customerName1}</Typography>
          <Typography>{customer?.customerName2}</Typography>
          <Typography>
            {customer?.street} {customer?.houseNumber}
          </Typography>
          <Typography>
            {customer?.cityPostalCode} {customer?.city}, {customer?.countryKey}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const getSubcontractorDates = (
    subcontractorRecords: SubcontractorRecord[],
    role: ActivityDifferentiator
  ) => {
    const subcontractor = subcontractorRecords.find(
      (subcontractor) => subcontractor.activityDifferentiator === role
    );

    const startDate = formatDate(subcontractor?.plannedStartDate);
    const targetDate = formatDate(subcontractor?.plannedEndDate);
    return [startDate, targetDate];
  };

  const getAssignmentDates = (
    currentEmployeeId: string,
    role: ActivityDifferentiator,
    assignees?: Assignment[]
  ) => {
    const currentAssignee = assignees?.find(
      (assignee) =>
        assignee.koneResourcePersonalNumber === currentEmployeeId &&
        assignee.activityDifferentiator === role
    );
    const startDate = formatDate(currentAssignee?.assignmentStartDate);
    const targetDate = formatDate(currentAssignee?.assignmentEndDate);
    return [startDate, targetDate];
  };
  const handleAddContact = () => {
    if (props.contacts.length >= 10) {
      setErrorMsg(true);
      return;
    }
    setSelectedItem(null);
    setShowDialog(DialogType.CONTACT);
  };
  const ContactInfoButton = () => {
    return (
      <>
        <Typography className={classes.greyHeadline} component="div">
          {t('teamList.contacts')}
        </Typography>
        <Grid item container spacing={2}>
          <Grid item xs={12}>
            {(props.contacts as Contact[])?.map((contact, index) => (
              <ContactInfoDetails
                setItemToRemove={setItemToRemove}
                contact={contact}
                key={index}
                setSelectedItem={setSelectedItem}
                setShowDialog={setShowDialog}
              />
            ))}
          </Grid>
          <Grid item xs={12}>
            <AddButton
              label={`+ ${t('teamList.addNew')}`}
              onClick={handleAddContact}
              style={{ paddingLeft: 0 }}
            />
          </Grid>
        </Grid>
      </>
    );
  };
  const deleteContact = async (contact: Contact) => {
    const token = await getTokenFunction();

    try {
      updateIsLoading(true);
      await remove(
        `v1/installations/${networkNumber}/contacts/${contact.guid}`,
        token,
        API_TYPE.APPLICATION
      );
      const updatedContacts = props.contacts.filter((c) => c.guid !== contact.guid);
      props.setContacts(updatedContacts);
      setShowDialog(DialogType.NONE);
    } catch (error) {
      updateErrorMessage({ message: t('teamList.cannotSaveContact'), error });
    } finally {
      updateIsLoading(false);
    }
  };
  const RemoveDialog = (props: { item: Contact; onCancel: () => void }): JSX.Element => {
    const { item, onCancel } = props;
    return (
      <>
        <DialogContent
          style={{ paddingTop: theme.spacing(6), paddingBottom: theme.spacing(2) }}
        >
          <Typography align="center" style={{ fontWeight: 'bold' }} gutterBottom>
            {t('teamList.remove.titleContact', {
              name:
                `${(item as Contact).firstName || ''} ${
                  (item as Contact).lastName || ''
                }`.trim() || '-',
            })}
          </Typography>
          <Typography align="center" style={{ fontWeight: 'bold' }}>
            {t('teamList.remove.question')}
          </Typography>
        </DialogContent>
        <DialogActions
          style={{ padding: theme.spacing(2), paddingBottom: theme.spacing(3) }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => deleteContact(item)}
            fullWidth
          >
            {t('teamList.remove.confirm')}
          </Button>
          <Button variant="contained" onClick={() => onCancel()} autoFocus fullWidth>
            {t('teamList.remove.cancel')}
          </Button>
        </DialogActions>
      </>
    );
  };
  const InstallationInfo = () => {
    const [startDate, targetDate] =
      role && isSubcontractor
        ? getSubcontractorDates(subcontractors ?? [], role)
        : getAssignmentDates(employeeId, role, assignees);

    const installerTotalHours = {
      message: t('installationStarting.installationHours'),
      value: Math.ceil(Number(installationData.totalInstallationHours)),
    };

    const testerTotalHours = {
      message: t('installationStarting.testingHours'),
      value: Math.ceil(Number(installationData.testerHours)),
    };

    const totalHours = isInstaller ? installerTotalHours : testerTotalHours;

    return (
      <Grid container item spacing={2}>
        <Grid item xs={6}>
          <Typography className={classes.upperCase}>
            {t('installationStarting.startDate')}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography data-testid="start-date"> {startDate ?? ''}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography className={classes.upperCase}>
            {t('installationStarting.targetDate')}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography data-testid="target-date"> {targetDate ?? ''}</Typography>
        </Grid>
        {!isSubcontractor && (
          <>
            <Grid item xs={6}>
              <Typography className={classes.upperCase}>{totalHours.message}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography data-testid="total-hours">{totalHours.value}</Typography>
            </Grid>
          </>
        )}
        <Grid item xs={6}>
          <Typography className={classes.upperCase}>
            {t('supervisorNewInstallation.inspectionDate')}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          {newDate ? (
            <>
              <DatePicker
                name="Inspection Date"
                dateTime={inspectDate.toISOString()}
                onDateSelected={handleDateChange}
                label={t('supervisorNewInstallation.inspectionDate')}
                newDate={newDate}
                setNewDate={setNewDate}
              />
            </>
          ) : (
            <>
              <DatePicker
                name="Inspection Date"
                dateTime={inspectDate.toISOString()}
                onDateSelected={handleDateChange}
                label={t('supervisorNewInstallation.inspectionDate')}
                newDate={newDate}
                setNewDate={setNewDate}
              />
            </>
          )}
        </Grid>

        <Grid container className={classes.additionalInfoLinks}>
          <Grid item xs={6}>
            {!isSubcontractor && (
              <Typography>
                <Link
                  href={`${process.env.REACT_APP_TRACE_PDM_URL}/equipment/${installationData.equipmentNumber}`}
                >
                  {t('installationStarting.openTracePDM')}
                </Link>
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Box className={classes.root}>
      {(isInstaller || isTester) && (
        <>
          <ContactInfo />
          <Divider className={classes.divider} />
          <InstallationInfo />
          <Divider className={classes.divider} />
          <ContactInfoButton />
          <Divider className={classes.divider} />
        </>
      )}
      {isTester && (
        <InstallationStartingTester handover={handover} setAllowStart={setAllowStart} />
      )}
      <ErrorMessageDialog
        editValue={errorMsg}
        handleEditChange={setErrorMsg}
        typeOfError="contact"
      />
      {isInstaller ? (
        installationData?.networkTag === 'MOD' ? (
          <Button
            data-testid="installation-button"
            id="installation-button"
            variant="contained"
            color="primary"
            onClick={handleInstallPlanButtonClick}
          >
            <Typography>
              {' '}
              {t('installationStarting.proceedToInstallationPlan')}{' '}
            </Typography>
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            data-testid="proceed-to-checklist-button"
            onClick={handleChecklistButtonClick}
          >
            {t('installationStarting.proceedToPreInstallationChecklist')}
          </Button>
        )
      ) : (
        <Button
          size="large"
          variant="contained"
          color="primary"
          disabled={!allowStart}
          onClick={onStartingInstallation}
          data-testid="start-button"
        >
          {t('installationStarting.start')}
        </Button>
      )}
      <Dialog
        data-testid="dialog-form"
        maxWidth="sm"
        fullWidth
        open={showDialog !== DialogType.NONE}
        scroll="paper"
      >
        <CircleCancel
          className={classes.closeDialogIcon}
          onClick={() => {
            setShowDialog(DialogType.NONE);
            setSelectedItem(null);
          }}
        />
        {showDialog === DialogType.CONTACT && (
          <DialogContent style={{ padding: 0 }}>
            <ContactForm
              contactToEdit={(selectedItem || undefined) as Contact | undefined}
              onSubmit={(contact) =>
                sendContact(contact, props.setContacts, props.contacts)
              }
              onClose={() => setShowDialog(DialogType.NONE)}
            />
          </DialogContent>
        )}
        {showDialog === DialogType.REMOVE && itemToRemove && (
          <RemoveDialog
            item={itemToRemove}
            onCancel={() => setShowDialog(DialogType.NONE)}
          />
        )}
      </Dialog>
      <InfoModal
        closeButtonText={t('installationStarting.ok')}
        message={getDialogMessage()}
        open={
          dialogType === InfoModalType.CONFIRMATION ||
          dialogType === InfoModalType.IS_OFFLINE
        }
        onClose={onDialogClose}
        isCenteredMessage
      />
    </Box>
  );
};

export default InstallationStarting;
